// Modal_AssetsActions.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ThemeProvider,
  TextField,
  Box,
} from '@mui/material';
import { t } from './Util_format';
import { theme } from './theme';
import {
  fetchRenameAsset,
  fetchUpdateAssetBalance,
  fetchUpdateAssetEstimatedValue,
  fetchUpdateTa,
} from './Util_API_calls';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // For deposit
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // For withdraw
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { NumericFormat } from 'react-number-format';
import ListIcon from '@mui/icons-material/List';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// STEP 1: Import Modal_SharesTransactions component
import Modal_SharesTransactions from './Modal_SharesTransactions';

function Modal_AssetsActions({ onClose, isOpen, assetType, asset, trading_accounts, handleRefreshAssets }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [renameMode, setRenameMode] = useState(false);
  const [updateBalanceMode, setUpdateBalanceMode] = useState(false);
  const [updateEstimatedValueMode, setUpdateEstimatedValueMode] = useState(false);
  const [newAssetName, setNewAssetName] = useState('');
  const [newAssetBalance, setNewAssetBalance] = useState('');
  const [newAssetEstimatedValue, setNewAssetEstimatedValue] = useState('');
  const [ModalAssetLabel, setModalAssetLabel] = useState('');
  const [updateTaMode, setUpdateTaMode] = useState(false);
  const [newTaID, setNewTaID] = useState('');
  const balanceInputRef = useRef(null);
  const estimatedValueInputRef = useRef(null);
  const [assetData, setAssetData] = useState(asset);

  // STEP 2: Add state variable for Transactions Modal
  const [isTransactionsModalOpen, setIsTransactionsModalOpen] = useState(false);

  const handleCancelUpdateBalance = () => {
    setErrorMessage('');
    setUpdateBalanceMode(false);
  };

  const handleCancelUpdateEstimatedValue = () => {
    setErrorMessage('');
    setUpdateEstimatedValueMode(false);
  };

  const handleUpdateBalanceClick = () => {
    setErrorMessage('');
    setUpdateBalanceMode(true);
    setRenameMode(false);
    setNewAssetBalance(assetData.dynamic_balance[assetData.user_asset_currency]);
  };

  const handleUpdateEstimatedValueClick = () => {
    setErrorMessage('');
    setUpdateEstimatedValueMode(true);
    setRenameMode(false);
    setNewAssetEstimatedValue(assetData.product_last_value[assetData.user_asset_product_currency]);
  };

  const handleSaveNewBalance = async () => {
    setErrorMessage('');
    setIsProcessing(true); // Start processing

    if (assetData && assetData.user_asset_id) {
      try {
        await fetchUpdateAssetBalance(assetType, assetData.user_asset_id, newAssetBalance);
        // Update the asset's balance in the local state
        setAssetData((prevAssetData) => ({
          ...prevAssetData,
          dynamic_balance: {
            ...prevAssetData.dynamic_balance,
            [assetData.user_asset_currency]: newAssetBalance,
          },
        }));
        handleRefreshAssets();
      } catch (error) {
        console.error('Error updating asset balance', error);
        setErrorMessage(t('unknown_api_error'));
      }
    } else {
      console.error('Asset ID is missing');
      setErrorMessage(t('asset_id_missing'));
    }
    setIsProcessing(false); // End processing
    setUpdateBalanceMode(false);
  };

  const handleSaveNewEstimatedValue = async () => {
    setErrorMessage('');
    setIsProcessing(true); // Start processing

    if (assetData && assetData.user_asset_id) {
      try {
        await fetchUpdateAssetEstimatedValue(assetType, assetData.user_asset_id, newAssetEstimatedValue);
        // Update the estimated value in the local state
        setAssetData((prevAssetData) => ({
          ...prevAssetData,
          product_last_value: {
            ...prevAssetData.product_last_value,
            [assetData.user_asset_product_currency]: newAssetEstimatedValue,
          },
        }));
        handleRefreshAssets();
      } catch (error) {
        console.error('Error updating asset estimated value', error);
        setErrorMessage(t('unknown_api_error'));
      }
    } else {
      console.error('Asset ID is missing');
      setErrorMessage(t('asset_id_missing'));
    }
    setIsProcessing(false); // End processing
    setUpdateEstimatedValueMode(false);
  };

  const handleRenameClick = () => {
    setErrorMessage('');
    setRenameMode(true);
    setUpdateBalanceMode(false);
    setUpdateEstimatedValueMode(false);
    setUpdateTaMode(false);
    setNewAssetName(assetData.user_asset_label);
  };

  const handleUpdateTaClick = () => {
    setErrorMessage('');
    setUpdateTaMode(true);
    setRenameMode(false);
    setUpdateBalanceMode(false);
    setUpdateEstimatedValueMode(false);
    setNewTaID(assetData.user_ta_id);
  };

  const handleCancelRename = () => {
    setErrorMessage('');
    setRenameMode(false);
    setUpdateBalanceMode(false);
    setUpdateEstimatedValueMode(false);
    setUpdateTaMode(false);
  };

  const handleCancelUpdateTa = () => {
    setErrorMessage('');
    setRenameMode(false);
    setUpdateBalanceMode(false);
    setUpdateEstimatedValueMode(false);
    setUpdateTaMode(false);
  };

  const navigate = useNavigate();

  const handleOpenBuyMoreSharesModal = () => {
    navigate('/assets/' + assetType + '/buy/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenBuyMoreEtfsModal = () => {
    navigate('/assets/' + assetType + '/buy/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenSellSharesModal = () => {
    navigate('/assets/' + assetType + '/sell/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenSellEtfsModal = () => {
    navigate('/assets/' + assetType + '/sell/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenSellCommoditiesModal = () => {
    navigate('/assets/' + assetType + '/sell/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenBuyMoreCommoditiesModal = () => {
    navigate('/assets/' + assetType + '/buy/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenDeleteAssetBankAccountModal = () => {
    const balance = parseFloat(assetData.dynamic_balance[assetData.user_asset_currency]) || 0;
    if (balance !== 0) {
      setErrorMessage(t('cannot_close_asset_with_balance'));
    } else {
      navigate('/assets/' + assetType + '/delete/' + assetData.user_asset_id); // Navigate to the desired URL
      onClose(); // Close the current modal
    }
  };

  const handleOpenDeleteAssetCreditsModal = () => {
    navigate('/assets/' + assetType + '/delete/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenDeleteAssetVehiclesModal = () => {
    navigate('/assets/' + assetType + '/delete/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenDeleteRealEstatesModal = () => {
    navigate('/assets/' + assetType + '/delete/' + assetData.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleSaveNewName = async () => {
    setIsProcessing(true); // Start processing

    if (assetData && assetData.user_asset_id) {
      try {
        await fetchRenameAsset(assetType, assetData.user_asset_id, newAssetName);
        setModalAssetLabel(newAssetName);
        setAssetData((prevAssetData) => ({
          ...prevAssetData,
          user_asset_label: newAssetName,
        }));
        handleRefreshAssets();
      } catch (error) {
        console.error('Error renaming asset', error);
        setErrorMessage(t('unknown_api_error'));
      }
    } else {
      console.error('Asset ID is missing');
      setErrorMessage(t('asset_id_missing'));
    }
    setIsProcessing(false); // End processing
    setRenameMode(false);
  };

  const handleSaveTa = async () => {
    setIsProcessing(true); // Start processing

    if (assetData && assetData.user_asset_id) {
      try {
        await fetchUpdateTa(assetType, assetData.user_asset_id, newTaID);
        setAssetData((prevAssetData) => ({
          ...prevAssetData,
          user_ta_id: newTaID,
        }));
        handleRefreshAssets();
      } catch (error) {
        console.error('Error updating TA', error);
        setErrorMessage(t('unknown_api_error'));
      }
    } else {
      console.error('Asset ID is missing');
      setErrorMessage(t('asset_id_missing'));
    }
    setIsProcessing(false); // End processing
    setUpdateTaMode(false);
  };

  const openWithdrawMoneyModal = () => {
    setErrorMessage('');
    setUpdateBalanceMode(true);
    setRenameMode(false);
    setNewAssetBalance(assetData.dynamic_balance[assetData.user_asset_currency]);
  };

  const openDepositMoneyModal = () => {
    setErrorMessage('');
    setUpdateBalanceMode(true);
    setRenameMode(false);
    setNewAssetBalance(assetData.dynamic_balance[assetData.user_asset_currency]);
  };

  // STEP 3: Add function to open Transactions Modal
  const handleOpenTransactionsModal = () => {
    console.log('Opening Transactions Modal for assetId:', assetData.user_asset_id);
    setIsTransactionsModalOpen(true);
  };
  

  // STEP 4: Add function to close Transactions Modal
  const handleCloseTransactionsModal = () => {
    setIsTransactionsModalOpen(false);
  };

  const closeActionModal = () => {
    if (!isProcessing) {
      onClose();
      navigate(`/assets/${assetType}`);
    }
  };

  // Update ModalAssetLabel and assetData whenever the asset prop changes
  useEffect(() => {
    if (isOpen && asset) {
      setModalAssetLabel(asset.user_asset_label || 'Unknown');
      setAssetData(asset);
    }
    if (!isOpen) {
      setIsProcessing(false);
      setErrorMessage('');
      setRenameMode(false);
      setUpdateTaMode(false);
      setUpdateBalanceMode(false);
      setUpdateEstimatedValueMode(false);
      setNewAssetName('');
      setNewAssetBalance('');
      setNewAssetEstimatedValue('');
      setNewTaID('');
    }
  }, [isOpen, asset]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={isProcessing ? null : onClose} // Disable onClose callback when processing
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown={isProcessing} // Disable escape keydown when processing
      >
        <DialogTitle className="modal_title">
          {ModalAssetLabel}
          <br />
        </DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        <DialogContent>
          {/* Display current balance if assetType is 'bankaccounts' */}
          {assetType === 'bankaccounts' && assetData && (
            <Typography variant="h6" style={{ marginBottom: '16px' }}>
              {t('current_balance')}: {assetData.dynamic_balance[assetData.user_asset_currency]}{' '}
              {assetData.user_asset_currency}
            </Typography>
          )}
          <List>
            {(assetType === 'bankaccounts' ||
              assetType === 'commodities' ||
              assetType === 'credits' ||
              assetType === 'vehicles' ||
              assetType === 'realestates') && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {renameMode ? (
                  <>
                    <TextField
                      label={t('asset_name')}
                      variant="outlined"
                      value={newAssetName}
                      onChange={(e) => setNewAssetName(e.target.value)}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                      disabled={isProcessing}
                    />
                    <Button
                      onClick={handleCancelRename}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={handleSaveNewName}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleRenameClick} disabled={isProcessing}>
                    <ListItemText primary={t('rename_asset')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(assetType === 'bankaccounts' || assetType === 'credits') && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {updateBalanceMode ? (
                  <>
                    <NumericFormat
                      value={newAssetBalance}
                      thousandSeparator="."
                      decimalSeparator=","
                      customInput={TextField}
                      label={t('new_balance')}
                      variant="outlined"
                      name="new_balance"
                      decimalScale={2}
                      fullWidth
                      inputRef={balanceInputRef}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const { value } = values;
                        setNewAssetBalance(value); // Correctly update the newAssetBalance
                      }}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                    />
                    <Button
                      onClick={handleCancelUpdateBalance}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={handleSaveNewBalance}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleUpdateBalanceClick} disabled={isProcessing}>
                    <ListItemText primary={t('update_balance')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(assetType === 'vehicles' || assetType === 'realestates') && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {updateEstimatedValueMode ? (
                  <>
                    <NumericFormat
                      value={newAssetEstimatedValue}
                      thousandSeparator="."
                      decimalSeparator=","
                      customInput={TextField}
                      label={t('new_market_value')}
                      variant="outlined"
                      name="new_estimated_value"
                      decimalScale={2}
                      fullWidth
                      inputRef={estimatedValueInputRef}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const { value } = values;
                        setNewAssetEstimatedValue(value);
                      }}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                    />
                    <Button
                      onClick={handleCancelUpdateEstimatedValue}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={handleSaveNewEstimatedValue}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleUpdateEstimatedValueClick} disabled={isProcessing}>
                    <ListItemText primary={t('update_market_value')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(assetType === 'shares' || assetType === 'etfs' || assetType === 'commodities') && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {updateTaMode ? (
                  <>
                    <FormControl style={{ marginRight: '10px', width: '250px' }}>
                      <InputLabel id="account-select-label" className="assets_input-label">
                        {t('select_brokerage_account')}
                      </InputLabel>
                      <Select
                        labelId="account-select-label"
                        id="account-select"
                        value={newTaID}
                        label={t('select_brokerage_account')}
                        onChange={(e) => setNewTaID(e.target.value)}
                        color="primary"
                        IconComponent={ListIcon}
                        renderValue={(selected) => (
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                              {trading_accounts.find((account) => account.user_ta_id === selected)
                                ?.user_ta_label || ''}
                            </Typography>
                          </Box>
                        )}
                      >
                        {trading_accounts.map((account) => (
                          <MenuItem
                            key={`${account.user_ta_id}-${Math.random()}`}
                            value={account.user_ta_id}
                          >
                            <Typography variant="body1">{account.user_ta_label}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button
                      onClick={handleCancelUpdateTa}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={handleSaveTa}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleUpdateTaClick} disabled={isProcessing}>
                    <ListItemText primary={t('change_ta_' + assetType)} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {assetType === 'commodities' && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <ListItemButton onClick={handleOpenBuyMoreCommoditiesModal} disabled={isProcessing}>
                  <ListItemText primary={t('buy_more_items')} />
                  <AddShoppingCartIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'shares' && (
              <>
                <ListItem
                  disablePadding
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <ListItemButton onClick={handleOpenBuyMoreSharesModal} disabled={isProcessing}>
                    <ListItemText primary={t('buy_more_items')} />
                    <AddShoppingCartIcon />
                  </ListItemButton>
                </ListItem>

                {/* STEP 5: Add 'Vedi transazioni' option for shares */}
                <ListItem
                  disablePadding
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
<ListItemButton onClick={handleOpenTransactionsModal} disabled={isProcessing}>
  <ListItemText primary="Mostra Transazioni" />
  <ListIcon />
</ListItemButton>
                </ListItem>
              </>
            )}
            {assetType === 'etfs' && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <ListItemButton onClick={handleOpenBuyMoreEtfsModal} disabled={isProcessing}>
                  <ListItemText primary={t('buy_more_items')} />
                  <AddShoppingCartIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'bankaccounts' && (
              <ListItem disablePadding>
                <ListItemButton onClick={openWithdrawMoneyModal} disabled={isProcessing}>
                  <ListItemText primary={t('withdraw')} />
                  <ArrowDownwardIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'shares' && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <ListItemButton onClick={handleOpenSellSharesModal} disabled={isProcessing}>
                  <ListItemText primary={t('sell_items')} />
                  <MonetizationOnIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'etfs' && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <ListItemButton onClick={handleOpenSellEtfsModal} disabled={isProcessing}>
                  <ListItemText primary={t('sell_items')} />
                  <MonetizationOnIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'commodities' && (
              <ListItem
                disablePadding
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <ListItemButton onClick={handleOpenSellCommoditiesModal} disabled={isProcessing}>
                  <ListItemText primary={t('sell_items')} />
                  <MonetizationOnIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'bankaccounts' && (
              <ListItem disablePadding>
                <ListItemButton onClick={openDepositMoneyModal} disabled={isProcessing}>
                  <ListItemText primary={t('deposit')} />
                  <ArrowUpwardIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'bankaccounts' && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteAssetBankAccountModal} disabled={isProcessing}>
                  <ListItemText primary={t('close_asset_bankaccount')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'credits' && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteAssetCreditsModal} disabled={isProcessing}>
                  <ListItemText primary={t('close_asset_credit')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'vehicles' && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteAssetVehiclesModal} disabled={isProcessing}>
                  <ListItemText primary={t('dispose_vehicle')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
            {assetType === 'realestates' && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteRealEstatesModal} disabled={isProcessing}>
                  <ListItemText primary={t('dispose_realestate')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: 'gray',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkgray',
              },
            }}
            onClick={closeActionModal}
            variant="contained"
            disabled={isProcessing}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {isTransactionsModalOpen && (
  <Modal_SharesTransactions
    isOpen={isTransactionsModalOpen}
    onClose={handleCloseTransactionsModal}
    assetId={assetData.user_asset_id}
    assetLabel={assetData.user_asset_label}
    // Pass other props if needed
  />
)}
    </ThemeProvider>
  );
}

export default Modal_AssetsActions;
