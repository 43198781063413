// Modal_SharesTransactions.js

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { t } from './Util_format';
import { fetchShareTransactions } from './Util_API_calls';

function Modal_SharesTransactions({ isOpen, onClose, assetId, assetLabel }) {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const data = await fetchShareTransactions('assets-shares', assetId);
        if (data.status === 'success' && data.data) {
          setTransactions(data.data);
        } else {
          setErrorMessage(t('error_fetching_transactions'));
        }
      } catch (error) {
        console.error('Error fetching share transactions:', error);
        setErrorMessage(t('error_fetching_transactions'));
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      setIsLoading(true);
      setErrorMessage('');
      getTransactions();
    }
  }, [isOpen, assetId]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t('transactions_for')} "{assetLabel}"
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : transactions.length > 0 ? (
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t('transaction_date_buy_label')}</TableCell>
                  <TableCell>{t('transaction_date_sell_label')}</TableCell>
                  <TableCell>{t('items_buy_label')}</TableCell>
                  <TableCell>{t('items_sell_label')}</TableCell>
                  <TableCell>{t('price_buy_label')}</TableCell>
                  <TableCell>{t('price_sell_label')}</TableCell>
                  <TableCell>{t('product_currency_label')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell>{transaction.transaction_date_buy}</TableCell>
                    <TableCell>{transaction.transaction_date_sell}</TableCell>
                    <TableCell>{transaction.items_buy}</TableCell>
                    <TableCell>{transaction.items_sell}</TableCell>
                    <TableCell>{transaction.price_buy}</TableCell>
                    <TableCell>{transaction.price_sell}</TableCell>
                    <TableCell>{transaction.product_currency}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>{t('no_transactions_found')}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal_SharesTransactions;
